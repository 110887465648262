<template>
  <nav class="nav">
    <ul :class="['nav__links', 'nav__links--' + direction]">
      <li class="nav__item hover-underline" v-for="nav in navBar" :key="nav.id">
        <nuxt-link :to="nav.link" class="nav__link">
          <base-icon
            size="normal"
            class="mr-2"
            :icon="nav.icon"
            v-if="nav.icon && showIcons"
          />
          <span>
            {{ $t(nav.translationPath) }}
          </span>
        </nuxt-link>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
import { v4 as createUid } from 'uuid';

// ************* TYPES ************* //
interface Props {
  direction?: 'column' | 'row';
  showIcons?: boolean;
}

// ************* PROPS ************* //
const props = defineProps<Props>();

const navBar = computed(() => [
  {
    translationPath: 'nav.about',
    link: '/#about',
    icon: 'user',
    id: createUid(),
  },
  {
    translationPath: 'nav.projects',
    link: '/#projects',
    icon: 'newspaper',
    id: createUid(),
  },
  {
    translationPath: 'nav.contact',
    link: '/#contact',
    icon: 'envelope',
    id: createUid(),
  },
  {
    translationPath: 'nav.blog',
    link: '/blog',
    icon: 'clipboard-document',
    id: createUid(),
  },
]);

// const { data: aboutList } = useAsyncData('navBar', () => {
//   return queryContent('/about').find()
// })
// console.log(data)
</script>

<style scoped lang="scss">
.nav {
  &__links {
    display: flex;
    gap: 4rem;
    justify-content: space-around;
    @include respond('phone') {
      gap: 3rem;
    }
  }

  &__links--column {
    flex-direction: column;
  }

  &__item {
    list-style: none;
  }

  &__link:link,
  &__link:visited {
    color: var(--color-white);
    font-size: $font-large--2;
    @include respond('tab-land') {
      font-size: $font-large--3;
    }
    position: relative;
    display: flex;
  }
}

// For the mobile Navigation
.nav {
  &__links {
    display: flex;
    justify-content: space-around;
  }
}
</style>
