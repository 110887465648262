<template>
  <button
    class="btn-nav-wrapper"
    aria-label="Menu button to open mobile menu"
    @click="toggleMobileNav"
  >
    <svg
      id="menu-icon"
      ref="menuIcon"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 6 64 54.8"
      xml:space="preserve"
    >
      <path
        id="bar-bottom"
        ref="barBottom"
        d="M6.8,49.1h51.1c1.2,0,2.2,1.3,2.2,2.8l0,0c0,1.5-1,2.8-2.2,2.8H6.8c-1.2,0-2.2-1.3-2.2-2.8l0,0
	C4.6,50.3,5.6,49.1,6.8,49.1z"
      />

      <path
        id="bar-top"
        ref="barTop"
        d="M6.8,12h51.1c1.2,0,2.2,1.2,2.2,2.7l0,0c0,1.5-1,2.7-2.2,2.7H6.8c-1.2,0-2.2-1.2-2.2-2.7l0,0
	C4.6,13.2,5.6,12,6.8,12z"
      />

      <path
        id="bar-mid-l"
        ref="barMidL"
        d="M6.8,30.7c-1.2,0-2.2,1.2-2.2,2.7s1,2.7,2.2,2.7h25.4v-5.4H6.8z"
      />

      <path
        id="bar-mid-r"
        ref="barMidR"
        d="M57.9,30.7h-26v5.4h26c1.2,0,2.2-1.2,2.2-2.7S59.1,30.7,57.9,30.7z"
      />
    </svg>
  </button>
</template>

<script lang="ts" setup>
// ************* import UTILS & HELPERS ************* //
import { ref, onMounted, watch } from 'vue';
import { gsap } from 'gsap';
// ************* import STORES ************* //
import { useAppStore } from '@/stores/appStore';

// ************* STORES ************* //
const { toggleMobileNav, state: appState } = useAppStore();

// ************* local STATE ************* //
// const toggled = ref(false);
const barTop = ref();
const barMidL = ref();
const barMidR = ref();
const barBottom = ref();
const menuIcon = ref();

let tl;
let ctx;

onMounted(async () => {
  toggleMobileNav(false);
  ctx = gsap.context((self) => {
    gsap.defaults({
      ease: 'power1.inOut',
    });
    tl = gsap.timeline({ paused: true });
  });
});

onUnmounted(() => {
  ctx.revert();
});

// *************************** //
// Mobil Navigation Functionality
// *************************** //

function playTimeLine() {
  if (!barBottom.value) return;

  tl.to(
    barBottom.value,
    0.2,
    {
      y: -5,
    },
    0,
  )
    .to(
      barTop.value,
      0.3,
      {
        rotation: 45,
        y: 0,
        x: 10,
        transformOrigin: 'left top',
      },
      0.15,
    )
    .to(
      barBottom.value,
      0.3,
      {
        rotation: -45,
        y: 0,
        x: 10,
        transformOrigin: 'left bottom',
      },
      0.15,
    )
    .to(
      barMidL.value,
      0.4,
      {
        opacity: 0,
        scaleX: 0,
        transformOrigin: 'left',
      },
      0,
    )
    .to(
      barMidR.value,
      0.4,
      {
        opacity: 0,
        scaleX: 0,
        transformOrigin: 'right',
      },
      0,
    )
    .to(
      menuIcon.value,
      0.8,
      {
        rotation: 90,
      },
      0.1,
    );
}

watch(
  () => appState.showNavMenu,
  (showMobileMenu) => {
    if (!showMobileMenu) {
      tl.reverse();
    } else {
      playTimeLine();
      tl.play();
    }
  },
);
</script>

<style scoped lang="scss">
.btn-nav-wrapper {
  background: none;
  border: none;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  display: none;
  cursor: pointer;
  overflow: visible;

  @include respond(tab-land) {
    display: inline-block;
    position: fixed;
    top: 2rem;
    right: 2rem;
    z-index: 99;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.active-bg {
  background: $gradient-primary-left-to-right-transparent;
}

#menu-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;

  line,
  path {
    fill: #fff;
    transition: fill 0.15s ease-out;
  }

  #circle {
    stroke: #fff;
    opacity: 0;
    fill: none;
    stroke-width: 3px;
  }
}
</style>
