<template>
  <header
    ref="$header"
    class="header"
    :class="{ 'header--sticky-shrink': isIntersecting }"
  >
    <nuxt-link to="/" class="header__home-link">
      <base-logo />
    </nuxt-link>
    <nav-bar class="header__nav-bar" appear />
    <Teleport to="body">
      <Transition name="roleInRight">
        <mobile-nav-bar
          v-if="state.showNavMenu"
          @handle:nav-click="toggleNav"
        />
      </Transition>
    </Teleport>
    <base-language-selector class="language-selector" />
    <div class="header__bk-box">
      <base-hamburger />
      <base-button
        ref="navBtn"
        class="header__btn-email"
        :link="{ name: 'auth-index-login' }"
      >
        {{ $t('generics.login') }}
      </base-button>
      <div class="header__bk-oval">
        <img
          src="@/assets/svgs/bk_blur-oval.svg"
          alt="Background blur circle"
          class="header__bg-svg"
        />
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
// ************* import UTILS & HELPERS ************* //
import { onMounted, ref, nextTick, watch } from 'vue';
// ************* import COMPOSABLES ************* //
import NavBar from '@/components/navigation/NavBar';
import MobileNavBar from '@/components/navigation/MobileNavBar.vue';
import BaseHamburger from '@/components/library/buttons/BaseHamburger.vue';
import { useRoute } from 'vue-router';
// ************* import STORES ************* /
import { useAppStore } from '@/stores/appStore';
// ************* local STATE ************* //
const $header = ref<HTMLHeadElement>();
const isIntersecting = ref(false);
let heroObserver: IntersectionObserver;
// ************* STORES ************* //
const { state, toggleMobileNav } = useAppStore();
const route = useRoute();

function toggleNav() {
  toggleMobileNav(false);
}

const addStickyEffect = () => {
  if (!state.nodes.heroPage) return;
  const navHeight = $header.value?.getBoundingClientRect().height;
  const stickyNav = function (entries: IntersectionObserverEntry[]) {
    const [entry] = entries;
    isIntersecting.value = !entry.isIntersecting;
  };
  heroObserver = new IntersectionObserver(stickyNav, {
    root: null,
    threshold: 0,
    rootMargin: `-${navHeight}px`,
  });
  heroObserver.observe(state.nodes.heroPage);
};

watch(route, ({ name }) => {
  if (name === 'index') {
    nextTick(() => {
      addStickyEffect();
    });
  } else {
    if (heroObserver) heroObserver.disconnect();
  }
});

watch(
  () => state.showNavMenu,
  (val) => {
    val
      ? document.body.classList.add('freeze')
      : document.body.classList.remove('freeze');
  },
);

onMounted(() => {
  if (route.name === 'index') {
    nextTick(() => {
      addStickyEffect();
    });
  }
});
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 10rem;

  &--sticky-shrink {
    @include respond(tab-land) {
      position: static;
      box-shadow: none;
      background-color: transparent;
    }
    position: sticky;
    top: 0;
    left: 0;
    padding: 1rem 10rem;
    background-color: var(--color-accent-60);
    box-shadow: 0px 0 10px var(--color-primary-shade-500);
    z-index: 100;
  }

  @include respond(phone) {
    padding: 2rem;
  }

  &__home-link {
    cursor: pointer;
  }

  &__nav-bar {
    width: 100%;
    max-width: 100rem;

    @include respond(tab-land) {
      display: none;
    }
  }

  &__logo-link {
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  &__logo {
    width: 12rem;
    height: 8rem;
  }

  &__logo-svg {
    width: 100%;
    height: 100%;
  }

  &__nav {
    flex-grow: 1;

    @include respond(tab-land) {
      display: none;
    }
  }

  &__btn-email {
    @include respond(tab-land) {
      display: none;
    }
  }

  &__bk-oval {
    position: absolute;
    z-index: -1;
    top: -14rem;
    right: -5rem;
    //width: 60rem;
    border-radius: 50%;
    height: 30rem;

    @include respond(tab-land) {
      right: -1rem;
      width: 40rem;
      height: 40rem;
    }
    @include respond(phone) {
      display: none;
    }
  }

  &__bg-svg {
    width: 100%;
    height: 100%;
    //transform: translate(50%, -70%);
    @include respond(tab-port) {
      display: none;
    }
  }

  &__icon-moon {
    position: absolute;
    // animation: rotate-around 12s linear 0s infinite normal none;

    @include respond(tab-port) {
      display: none;
    }
  }
}

.language-selector {
  @include respond(tab-land) {
    display: none;
  }
}
</style>
