export const useDom = () => {
  const makePageSticky = (shouldMakeSticky: boolean) => {
    // if (!document) return;
    // if (shouldMakeSticky) {
    //   document.body.style.overflowY = 'hidden';
    // } else {
    //   document.body.style.overflowY = 'auto';
    // }
  };
  return {
    makePageSticky,
  };
};
