<template>
  <div class="app-container" id="app">
    <client-only>
      <transition name="rotate" appear>
        <the-header />
      </transition>
    </client-only>
    <main>
      <slot />
    </main>
    <the-footer />
  </div>
</template>

<script lang="ts">
import TheHeader from '@/components/layout/TheHeader.vue';
import TheFooter from '@/components/layout/TheFooter.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: { TheHeader, TheFooter },
  head() {
    return {
      titleTemplate: 'Benjamin Scharf - Portfolio',
      meta: [
        {
          hid: 'portfolio',
          name: 'portfolio',
          content: 'Where you can find all the event taking',
        },
      ],
    };
  },
});
</script>
