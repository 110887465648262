import { defineStore, acceptHMRUpdate } from 'pinia';
import sprite from 'assets/sprite.svg';

interface ConfigState {
  mode: 'light' | 'dark';
}

export const socialLinks = [
  {
    link: 'https://twitter.com/v_ben_tec',
    icon: 'twitter',
  },
  {
    link: 'https://github.com/vBenTec',
    icon: 'github',
  },
  {
    link: 'https://www.linkedin.com/in/benjamin-scharf-29a549216/',
    icon: 'linkedIn',
  },
  {
    link: 'https://www.frontendmentor.io/profile/BenChis',
    icon: 'Icon_FrontEndMentor',
  },
];

export const useConfigStore = defineStore('configStore', () => {
  const state: ConfigState = reactive({});

  function updateState(
    key: keyof ConfigState,
    value: ConfigState[keyof ConfigState],
  ) {
    state[key] = value;
  }

  return {
    state,
    updateState,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConfigStore, import.meta.hot));
}
