<template>
  <dialog ref="mobileContainer" class="nav-mobile-container" open>
    <base-logo class="nav-mobile__logo" />
    <div class="links-container">
      <div class="nav-icon-wrapper" @click="$emit('handle:nav-click')">
        <nav-bar class="nav-bar" :show-icons="true" direction="column" />
      </div>
      <div class="links-page" @click="$emit('handle:nav-click')">
        <nuxt-link class="links-page__link hover-underline" to="/privacy">
          <base-icon size="normal" icon="shield-check" />
          <span>{{ $t('privacy_page.title') }}</span>
        </nuxt-link>
        <nuxt-link class="links-page__link hover-underline" to="/cookie">
          <base-icon size="normal" icon="shield-exclamation" />
          <span>{{ $t('cookie_page.title') }}</span>
        </nuxt-link>
      </div>
      <base-button
        ref="navBtn"
        size="small"
        :full-width="true"
        :link="{ name: 'auth-index-login' }"
      >
        {{ $t('generics.login') }}
      </base-button>
      <social-bar
        class="social-bar--mobile"
        size="normal"
        :social-icons="socialLinks"
      />
    </div>
    <base-language-selector class="language-selector" />
  </dialog>
</template>

<script lang="ts" setup>
import NavBar from '@/components/navigation/NavBar';
import SocialBar from '@/components/bars/SocialBar';

import { socialLinks } from '@/stores/configStore';
import { useAppStore } from '~/stores/appStore';

// ************* STORES ************* //
const { state } = useAppStore();

// ************* COMPOSABLES ************* //
const { makePageSticky } = useDom();

// ************* WATCHERS ************* //
watchEffect(() => {
  makePageSticky(state.showNavMenu);
});
</script>

<style scoped lang="scss">
.nav-mobile-container {
  background-color: var(--color-black);
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  padding: 3.5rem 12rem;
  transition: all 250ms;
  transform-origin: top right;

  @include respond(tab-land) {
    display: flex;
    gap: 5rem;
    align-items: center;
    justify-content: center;
    padding: 3.5rem 0;
  }

  @include respond(phone) {
    padding: 0;
  }
}

.language-selector {
  width: fit-content;
  position: absolute !important;
  top: 13%;
  right: 0;
  transform: scale(0.6);
}

.nav-icon-wrapper {
  display: flex;
  gap: 4rem;

  & :deep(.social-bar) {
    gap: 3.4rem;
  }
}

.nav-bar {
  margin-bottom: 4rem;
  @include respond('phone') {
    margin-bottom: 3rem;
  }
}

.nav-mobile {
  &__logo {
    position: absolute;
    top: 1rem;
    left: 2rem;
    margin-right: 16rem;
    @include respond(phone) {
      margin-right: 16rem;
    }
  }

  &__nav {
    margin-right: 8rem;
  }
}

.social-nav-wrapper {
  display: flex;
  justify-content: space-between;

  & .nav__list:last-child {
    margin-bottom: 4rem;
  }
}

.links-container {
  display: flex;
  flex-direction: column;
}

.social-bar--mobile {
  margin-left: 0;
  margin-top: 4rem;
  align-self: center;
}

.links-page {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 3rem;
  color: var(--color-white);

  &__link {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    font-size: $font-large--2;
    @include respond('tab-land') {
      font-size: $font-large--3;
    }
  }
}
</style>
